function formattSalutationEnum(addressDto) {
  switch (addressDto.salutation) {
    case 'MISS':
      return 'Frau';
    case 'MISTER':
      return 'Herr';
    case 'COUPLE':
      return 'Eheleute';
    case 'ALL':
      return '';
    case 'DIVERSE':
      return '';
    default:
      return null;
  }
}

function formatCompactAddress(addressDto) {
  if (!addressDto) {
    return null;
  }
  const compactAddress = `${emptyIfNull(addressDto.zipCode)} ${emptyIfNull(
    addressDto.city
  )}, ${emptyIfNull(addressDto.street)} ${emptyIfNull(
    addressDto.houseNumber
  )} ${emptyIfNull(addressDto.houseNumberSupplement)}`;
  if (compactAddress.trim() === ',') {
    return null;
  }
  return compactAddress;
}

function extractFullName(addressDto) {
  if (!addressDto) {
    return null;
  }
  const fullName = `${emptyIfNull(addressDto.firstName)} ${emptyIfNull(
    addressDto.lastName
  )}`.trim();
  if (fullName.length === 0) {
    return null;
  }
  return fullName;
}

function emptyIfNull(string) {
  return string !== null ? string : '';
}
export default {
  formattSalutationEnum,
  formatCompactAddress,
  extractFullName
};
